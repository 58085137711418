<template>
  <div class="u-files-up w-100 pt-5">
    <b-row class="mx-0">
      <b-col :cols="files.length ? '6' : '12'"
        ><component
          :is="type === 'form-textbox' ? 'div' : isComponent"
          id="u-files-up"
          class="rounded d-flex justify-content-center align-items-center"
          :class="dropzoneClasses"
          duplicateCheck
          ref="vue-dropzone-files-ref"
          :disabled="true"
          :options="options"
          :style="{
            height: `${containerHeight - 400}px`,
          }"
          :useCustomSlot="true"
          @vdropzone-files-added="addFile"
          @vdropzone-upload-progress="progressFiles"
          @vdropzone-error="setFileError"
          @vdropzone-success="setFileSuccess"
          @vdropzone-processing="setProcessingFile"
          @vdropzone-total-upload-progress="setTotalProgress"
          @vdropzone-duplicate-file="duplicatedFile"
          @vdropzone-sending="sendFiles"
          @vdropzone-removed-file="removeFile"
        >
          <div class="dropzone-custom-content text-center">
            <div class="d-flex justify-content-center align-items-center mb-3">
              <img svg-inline :src="url" alt="Uello" :class="type" />
              <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
                {{ formattedTitle }}
              </p>
            </div>
            <h4
              v-if="isComponent !== 'div' && type === 'form-textbox'"
              class="dropzone-custom-title font-weight-bold text-transition text-center"
            >
              {{ $t('requestItems.formTextboxNextStep') }}
            </h4>

            <h4
              v-if="isComponent !== 'div' && type !== 'form-textbox'"
              class="dropzone-custom-title font-weight-bold text-transition text-center"
            >
              {{ $t('requestItems.dragAndDropMessageHeading', { extname: type.toUpperCase() }) }}
            </h4>

            <p
              v-if="isComponent !== 'div' && type !== 'form-textbox'"
              class="h6 m-0 text-transition text-center"
            >
              {{ $t('requestItems.dragAndDropMessageSubHeading') }}
            </p>
            <div>
              <v-select
                v-if="getChilds.length > 1"
                v-model="selected"
                ref="vue-select"
                label="text"
                maxHeight="100px"
                class="mt-2"
                @input="setOperation"
                :options="getChilds"
                :placeholder="$t('form.placeholders.selectPartner')"
                :clearable="false"
                :clearSearchOnSelect="false"
              ></v-select>
            </div>
            <div class="d-flex flex-column align-items-center" @click.prevent="() => {}">
              <v-select
                v-if="operations && operations.operation && isArrayOperations"
                v-model="inArraySelected"
                @input="operationSelected"
                ref="vue-select"
                label="name"
                maxHeight="100px"
                class="mt-2"
                :options="operations.operation"
                :reduce="setNewOperation"
                :placeholder="$t('form.placeholders.selectOperation')"
                :clearable="false"
                :clearSearchOnSelect="false"
              ></v-select>
              <b-button
                v-if="isComponent !== 'div' && type === 'form-textbox'"
                size="sm"
                variant="primary"
                class="mt-4 px-3 mx-auto"
                :class="{ 'have-operations': isArrayOperations }"
                :disabled="nextIsDisabled"
                @click="goToTextForm"
                >{{ $t('requestItems.formTextboxGoToForm') }}</b-button
              >
              <b-button
                v-if="isComponent !== 'div' && type !== 'form-textbox'"
                v-show="isOperationSelected"
                size="sm"
                variant="primary"
                class="mt-4 px-3 mx-auto"
                id="uploadFileButton"
                >{{ $t('requestItems.searchFileButton') }}</b-button
              >
            </div>
          </div>
        </component></b-col
      >
      <b-col cols="6" v-if="files.length">
        <div
          class="d-flex justify-content-between align-items-center w-100 process-status"
          v-if="files.length"
        >
          <p>
            <small class="text-dark-darken font-weight-bold">{{
              $t(`requestItems.totalFiles`, { count: files.length })
            }}</small>
          </p>
          <p>
            <small class="text-info font-weight-bold">{{
              $t(`requestItems.sendingFiles`, { count: filesUploading })
            }}</small>
          </p>
          <p>
            <small class="text-primary font-weight-bold">{{
              $t(`requestItems.completedFiles`, { count: completedFiles })
            }}</small>
          </p>
          <p>
            <small class="text-warning font-weight-bold">{{
              $t(`requestItems.filedFiles`, { count: filedFiles })
            }}</small>
          </p>
        </div>
        <perfect-scrollbar
          :style="{
            height: `${containerHeight >= 668 ? containerHeight - 480 : containerHeight - 490}px`,
          }"
          class="pr-3"
        >
          <file-item
            v-for="file in files"
            :key="file.upload.uuid"
            :ref="file.upload.uuid"
            :file="file"
            :type="type"
            :dropzoneRef="$refs['vue-dropzone-files-ref']"
          />
        </perfect-scrollbar>
        <div class="d-flex justify-content-end align-items-center mt-4 pr-60">
          <uello-button
            color="red"
            size="small"
            class="px-3"
            :disabled="!isOperationSelected || !sendAuthorize"
            @click="$emit('verifyFiles', true)"
            text="Próximo"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UelloButton } from '@uello/componentello';

import * as types from '@/store/types';
import { safeStorage } from '@/utils';
import { centralWindowSize } from '@/mixins';

export default {
  name: 'u-files-up',
  mixins: [centralWindowSize],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    FileItem: () => import('./FileItem.vue'),
    UelloButton,
  },
  data() {
    return {
      files: [],
      filesUploading: 0,
      totalUploadPercentage: 0,
      completedFiles: 0,
      filedFiles: 0,
      options: {
        url: `${process.env.VUE_APP_API_BASE_URL}/orders/normalize/${this.type}`,
        thumbnailWidth: 200,
        addRemoveLinks: true,
        uploadMultiple: false,
        clickable: '#uploadFileButton',
      },
      items: {
        failed: [],
        success: [],
      },
      selected: null,
      inArraySelected: null,
      operations: null,
      operationInArray: null,
      isOperationSelected: false,
    };
  },
  beforeMount() {
    this.options = {
      ...this.options,
      // accept: `.${this.type !== 'copy' ? this.type : 'text'}`,
      acceptedFiles: `.${this.type !== 'copy' ? this.type : 'text'}`,
      headers: { Authorization: `Bearer ${this.token}` },
    };

    this.verifyPartnerIsUnique();
  },
  computed: {
    ...mapGetters({
      token: types.GETTER_TOKEN,
      getChilds: types.GETTER_PARTNER_CHILDS,
      chosenOperation: types.GETTER_CHOSEN_OPERATION,
    }),
    nextIsDisabled() {
      const { has_childs } = safeStorage.getItem('config');

      if (!has_childs) {
        return !this.inArraySelected;
      }

      return !this.inArraySelected && !!this.getChilds.length;
    },
    isArrayOperations() {
      if (
        !this.getChilds.length &&
        this.operations &&
        this.operations.operation &&
        Array.isArray(this.operations.operation)
      ) {
        return true;
      }

      if (!this.getChilds.length) {
        return false;
      }

      return !!(
        this.operations &&
        this.operations.operation &&
        Array.isArray(this.operations.operation)
      );
    },
    url() {
      // eslint-disable-next-line
      const image = require(`@/assets/images/icon-${this.type}-gray.svg`);

      return image;
    },
    sendAuthorize() {
      return this.filesUploading + this.completedFiles + this.filedFiles >= this.files.length;
      // return false;
    },
    hasChilds() {
      const { has_childs } = safeStorage.getItem('config');

      return has_childs;
    },
    isComponent() {
      return 'vue-dropzone';
    },
    withFormTextbox() {
      return this.type === 'form-textbox' && (this.operations || !this.hasChilds);
    },
    dropzoneClasses() {
      const isDiv = this.type === 'form-textbox' || this.isComponent === 'div';

      return isDiv
        ? 'border-light roundes display-flex justify-content-center align-items-center'
        : '';
    },
    formattedTitle() {
      return `${
        this.type !== 'form-textbox' ? this.type : this.$t('requestItems.formTextbox')
      }`.toUpperCase();
    },
  },
  watch: {
    inArraySelected(value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.$emit('operation', this.operationInArray);
        this.$store.commit(`@orders/MUTATE_SET_CHOSEN_OPERATIONS`, value.value);
      }
    },
  },
  methods: {
    addFile(fileList) {
      this.files = [...this.files, ...Array.from(fileList)];
    },
    sendFiles(file, xhr, formData) {
      formData.append('chosenOperation', this.chosenOperation);
    },
    progressFiles(file, progress) {
      const [fileItem] = this.$refs[file.upload.uuid];
      fileItem.progress = progress;
    },
    verifyPartnerIsUnique() {
      const { operations, document: cnpj, has_childs } = safeStorage.getItem('config');

      if (!has_childs) {
        const [op] = operations;
        this.selected = op;

        this.operationInArray = {
          cnpj,
          operation: operations[0].id,
        };

        this.operations = {
          cnpj,
          operation: operations,
        };

        if (operations.length === 1) {
          this.inArraySelected = operations.shift();
        }

        this.$emit('operation', this.operations);
      }
    },
    setFileError(file) {
      const [fileItem] = this.$refs[file.upload.uuid];
      fileItem.error = file.status === 'error';
      this.filesUploading -= 1;
      this.completedFiles = this.items.failed.length;
    },
    setFileSuccess(file, response) {
      const { data } = response;
      const { success, failed } = data;

      this.items = {
        success: [...this.items.success, ...success],
        failed: [...this.items.failed, ...failed],
      };

      this.filesUploading -= 1;
      this.$emit('filesup', this.items);
      this.completedFiles = this.items.success.length + this.items.failed.length;
    },
    setProcessingFile(file) {
      this.filesUploading += file.status === 'uploading' ? 1 : 0;
    },
    setTotalProgress(totaluploadprogress) {
      this.totalUploadPercentage = totaluploadprogress;
    },
    duplicatedFile(file) {
      this.files = this.files.filter(f => f.upload.uuid !== file.upload.uuid);
      this.$toast.error(this.$t('messages.duplicatedFile', { filename: file.name }));
    },
    removeFile(file) {
      this.files = this.files.filter(f => f.upload.uuid !== file.upload.uuid);
      this.filedFiles -= 1;
    },
    setOperation(op) {
      let partner = safeStorage.getItem('config');

      const { partner_childs, has_childs } = partner;

      if (has_childs) {
        partner = partner_childs.find(p => p.document === op.value);
      }

      const filteredOperations = partner.operations
        ? partner.operations.filter(ope => ope && ope.type !== 'spot')
        : partner.operations;

      if (filteredOperations) {
        this.operations = this.selected && {
          operation: filteredOperations,
          cnpj: partner.document,
        };
      }

      this.isOperationSelected = false;

      this.$emit('operation', this.operations);

      return op;
    },
    operationSelected() {
      this.isOperationSelected = true;
    },
    setNewOperation(op) {
      this.operationInArray = {
        cnpj: this.hasChilds ? this.selected.value : this.operations.cnpj,
        operation: op.id,
      };

      return {
        text: op.name,
        value: op.id,
      };
    },
    alterPartner() {
      this.selected = null;
      this.operations = null;
      this.inArraySelected = null;
    },
    goToTextForm() {
      const operation = {
        ...this.inArraySelected,
        cnpj: this.hasChilds ? this.selected.value : this.operations.cnpj,
      };

      this.$emit('form-textbox-ready', this.isArrayOperations ? operation : null);
    },
  },
};
</script>

<style lang="scss">
$vs-state-active-bg: var(--primary) !default;

.pr-60 {
  padding-right: 60px;
}

.border-light {
  border: 2px solid var(--lighen);
}

.u-files-up {
  img.form-textbox {
    width: 50px;
  }

  .have-operations {
    width: 352.38px;
  }

  .vs__dropdown-menu li {
    color: var(--transition);
    font-weight: bold;

    &:hover,
    &:active,
    &.vs__dropdown-option--highlight {
      color: #fff;
      background: var(--primary);
    }
  }

  .vs__search,
  .vs__search:focus {
    color: var(--transition);
    font-weight: bold;
  }

  .btn.btn-outline-secondary {
    &:hover {
      svg {
        fill: #fff !important;
      }
    }
  }

  .vs__dropdown-toggle {
    border: 2px solid var(--light);
    width: 352.39px;
    height: 44px;
  }

  .request-message {
    border: 2px solid var(--light);
  }

  .dropzone-custom-content,
  .dz-message {
    display: block !important;
    font-family: 'Open Sans', sans-serif !important;
  }

  .dz-preview {
    display: none !important;
  }

  .dz-default.dz-message {
    display: block !important;
  }

  .process-status {
    small {
      font-size: 10px;
    }
  }
}
</style>
